import React from "react";
import Chart from "react-apexcharts";

export default function ApexBarChart(props) {
	let series = props.series//[{data: [21, 22, 10, 28, 16, 21, 13, 30]}]
	let options = {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: ['#297f87', '#ffc93f', '#0fb9b1', '#759699'],
    //colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: props.categories,
      labels: {
        style: {
          //colors: colors,
          fontSize: '12px'
        }
      }
    }
  }


	return (
		<div id="chart" >
     <h2
        className="text-base truncate"
        title={props.title}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
>
  {props.title}
</h2>
{/* porcentaje el tital esta en la primera posision */}
    {
      props.porcenjate&&series[0].data?.map((s, index) =>  {
        if(index === 0) return;
        //porcentage de la categoria con el viñeta de su color
        const porcentaje=((s/ series[0].data[0]) * 100)||0;
        return(<div key={index} className="flex items-center gap-2 mt-3">
          {/* circulo  de su color */}
          <div style={{width:12,height:12, backgroundColor:options.colors[index], borderRadius:'100%'}}></div>
          {/* <span className="text-primary text-sm">{props.categories[index]}:</span>  */}
          <span className="text-primary text-sm">{(porcentaje).toFixed(2)}%</span>
        </div>)
        }
       ) 
    }
			<Chart options={options} series={series} type="bar" height={350}  />
		</div>
    )
}


          
            
