import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../libraries/notifications';
import LayoutWithSidebar from '../components/layout/LayoutWithSidebar';
import Navbar from '../components/layout/Navbar';
import Icon from '../libraries/icons';
import { history } from '../routes';
import config from '../config';
//import { useTranslation } from 'react-i18next';
import PrivateMenuItem from '../components/commons/PrivateMenuItem';
import { capitalize, getOwner, removeDuplicates, months } from '../libraries/utils';
import transactionActions from '../context/transactions/actions';
import usersActions from '../context/users/actions';
import businessesActions from '../context/businesses/actions';
import companiesActions from '../context/companies/actions';
import actionsActions from '../context/actions/actions';

import { isThisWeek, isThisMonth, getMonth } from 'date-fns'
import SelectInput from '../components/forms/SelectInput';

import PieChart from '../components/charts/PieChart';
import LineChart from '../components/charts/LineChart';
import ApexBarChart from '../components/charts/ApexBarChart';
import Loader from '../components/commons/Loader';
import StaticsFlow from './statics_flow/StaticsFlow';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: true,
      statistics: [],
      timeFilter: () => true,
      timeFilterOptions: [{value: "total", label: "Total"}, {value: "isThisWeek", label: "Por semana"}, {value: "isThisMonth", label: "Por mes"}, ],
      timeFilterDict: {isThisWeek: isThisWeek, isThisMonth: isThisMonth, total: () => true}
    }
  }

  componentDidMount() {
    this.getData()
  }

  getCompanyId = async () => {
    await this.props.onGetAllCompanies({ owner: getOwner(this.props.auth.user) })
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message))
      return null
    } else {
      if(companies.items.length){
        return companies.items[0].id;
      }
      return null
    }
  }

  getBusinessId = async () => {
    await this.props.onGetAllBusinesses({ owner: getOwner(this.props.auth.user) })
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message))
      return null
    } else {
      if(businesses.items.length){
        return businesses.items[0].id;
      }
      return null
    }
  }

  getData = async () => {
    let { roles } = this.props.auth.user
    let user_params = {}
    let trx_params = {}
    if (this.props.auth.user.roles.includes(config.ROLES.COMPANIES)) {
      let unique_id = await this.getCompanyId()
      if(unique_id){
        user_params = {unique_id: unique_id}
        trx_params = {owner: unique_id}
      }
    }

    if (this.props.auth.user.roles.includes(config.ROLES.BENEFITS)) {
      let unique_id = await this.getBusinessId()
      if(unique_id){
        trx_params = {source: unique_id}
      }
    }

    if (this.props.auth.user.roles.includes(config.ROLES.INITIATIVES)) {
      let unique_id = await this.getBusinessId()
      if(unique_id){
        trx_params = {source: unique_id}
      }
    }

    await this.getTransactions({...trx_params})
    console.log("SERVICES::USERS::PARAMS", {...user_params})
    await this.getUsers({...user_params})
    
    if(!this.state.transactions || !this.state.users){
      this.setState({loading:false})
      return
    }

    if (roles.includes(config.ROLES.ADMIN) || roles.includes(config.ROLES.COMPANIES)) {
      this.getUsersStatistics()
      this.getODSStatistics()
    }
    
    await this.getTransactionsStatistics()
    this.setState({ loading: false });
  }

  getTotalPointsAndCoints = (transactions) => {
    let points = 0
    let coins = 0
    
    let borrow_points = 0
    let borrow_coins = 0

    let actions_points = 0
    let actions_coins = 0

    let initiatives_points = 0
    let initiatives_coins = 0
    
    transactions.forEach(trx => {
      if (!trx.json_data?.points || !trx.json_data?.coins ||trx.archived === 1) {
        return
      }
      trx.json_data.points = parseInt(trx.json_data.points)
      trx.json_data.coins = parseInt(trx.json_data.coins)
      if(trx.type === config.TRANSACTIONS.BORROW.TYPE || trx.type === config.TRANSACTIONS.ACTION.TYPE || trx.type === config.TRANSACTIONS.INITIATIVE.TYPE){
        points += trx.json_data.points
        coins  += trx.json_data.coins
      }

      if(trx.type === config.TRANSACTIONS.BORROW.TYPE){
        borrow_points += trx.json_data.points 
        borrow_coins  += trx.json_data.coins 
      }
      if(trx.type === config.TRANSACTIONS.ACTION.TYPE){
        actions_points += trx.json_data.points
        actions_coins  += trx.json_data.coins
      }
      if(trx.type === config.TRANSACTIONS.INITIATIVE.TYPE){
        if(trx.status === "attended"){
          initiatives_points += trx.json_data.points
          initiatives_coins  += trx.json_data.coins          
        }
      }

    })

    return {points, coins, borrow_points, borrow_coins, actions_points, actions_coins, initiatives_points, initiatives_coins,}
  }

  getMonthlySeries = (data, time_prop) => {
    let series = []

    for(let i = 0; i < 11; i++){
      series.push( data.filter(d => getMonth(new Date(d[time_prop])) === i ).length)
    }

    return series
  }

  getTransactionsStatistics = () => {
    let { roles } = this.props.auth.user
    let { transactions, timeFilter } = this.state
 
    //Cantidad de empleados activos en última semana // getTransactions y sacar los users?
    const idsUsersHablitados=this.state.users.map(u=>u.id)
    let active_users_number = removeDuplicates(transactions.filter(t => idsUsersHablitados.includes(t.created_by)).filter(t => timeFilter(new Date(t.created_at))).map(t => t.created_by)).length
    let active_users = { stat: 'active_users', label: "Colaboradores activos", desc: "", number: active_users_number }
    

    //Cantidad de acciones de sostenibilidad . // getTransactions
    //y cuantas por cada acción en particular --> no

    let actions_number = transactions.filter(t => t.type === "action" && (t.json_data?.points !==0 || t.json_data?.coins !==0 )&& t.archived === 0 ).filter(t => timeFilter(new Date(t.created_at))).length   
    let actions = {stat: 'actions', label: "Acciones de Sostenibilidad", number: actions_number}

    console.log("monthly", this.getMonthlySeries(transactions.filter(t => t.type === "action"), "created_at"))
    //let actions_lineChart = {series: this.getMonthlySeries(transactions.filter(t => t.type === "action"), "created_at")}
    //this.getMonthlySeries(transactions.filter(t => t.type === "benefit" && t.sub_type !== "prize" && t.status === "consumed"), "created_at")
    //this.getMonthlySeries(transactions.filter(t => t.type === "action"), "created_at")

    //Cantidad de premios de la empresa utilizados
    //y cuántos de cada premio en particular --> no
    let prizes_number = transactions.filter(t => t.type === "benefit" && t.sub_type === "prize" && t.status === "consumed").filter(t => timeFilter(new Date(t.created_at))).length
    let prizes = {stat: 'prizes_consumed', label: "Premios entregados", number: prizes_number}

    //Cantidad de beneficios utilizados 
    //y cuantos de cada beneficio en particular -> no
    // let benefits_number = transactions.filter(t => t.type === "benefit" && t.sub_type !== "prize" && t.status === "consumed").filter(t => timeFilter(new Date(t.created_at))).length
    // let benefits = {stat: 'benefits_consumed', label: "Beneficios utilizados", number: benefits_number}

    //this.getTotalPointsAndCoints(transactions)

    //Cantidad de Productos prestados y cuantos por categoría y producto  // // getTransactions
    //Cantidad de Servicios prestados y cuantos por categoría y servicio // // getTransactions
    //Cantidad de favores realizados y cuantos por categoría y favor // // getTransactions
    //contar solo distintos?
    let products_borrowed_number = transactions.filter(t => t.sub_type === "product" && ["accepted", "completed", "on_user", "on_owner"].includes(t.status)).filter(t => timeFilter(new Date(t.created_at))).length
    let products_borrowed = {stat: 'products_borrowed', label: "Productos prestados", number: products_borrowed_number}
    let services_borrowed_number = transactions.filter(t => t.sub_type === "service").filter(t => timeFilter(new Date(t.created_at))).length
    // let services_borrowed = {stat: 'services_borrowed', label: "Servicios prestados", number: services_borrowed_number}
    let favors_borrowed_number = transactions.filter(t => t.sub_type === "favor" && ["accepted", "completed", "on_user", "on_owner"].includes(t.status)).filter(t => timeFilter(new Date(t.created_at))).length
    let favors_borrowed = {stat: 'favors_borrowed', label: "Favores prestados", number: favors_borrowed_number}


    //Cantidad de Ayudas a la comunidad realizadas y por ONG // getTransactions
    //Cantidad de Acciones de reciclado realizadas y por ONG // getTransactions
    //Cantidad de Acciones de donaciones realizadas y por ONG // getTransactions

    let volunteering_number = transactions.filter(t => t.sub_type === "volunteering" && t.status === "attended").filter(t => timeFilter(new Date(t.created_at)))?.length
    // let volunteering = {stat: 'volunteering_attended', label: "Asistencias a voluntariados", number: volunteering_number,}
    let recycling_number = transactions.filter(t => t.sub_type === "recycling" && t.status === "attended").filter(t => timeFilter(new Date(t.created_at)))?.length
    // let recycling = {stat: 'recycling_attended', label: "Asistencias a reciclajes", number: recycling_number,}
    let donations_number = transactions.filter(t => t.sub_type === "donations" && t.status === "attended").filter(t => timeFilter(new Date(t.created_at)))?.length
    // let donations = {stat: 'donations_attended', label: "Asistencias a donaciones", number: donations_number,}

    //Cantidad de puntos shary  y puntos de impacto generados en total y por sección (acciones sostenibles, Servicio Comunitario, Economía circular) // getTransactions
    let pointsAndCoins = this.getTotalPointsAndCoints(transactions.filter(t => timeFilter(new Date(t.created_at))))

    let {points, coins, borrow_points, borrow_coins, actions_points, actions_coins, initiatives_points, initiatives_coins} = pointsAndCoins
    let pointsAndCoinsObj = [
      {stat: 'shary_totals', label: "Total de Puntos Shary", desc: "Total generados", number: (coins)},
      {stat: 'impact_totals', label: "Total de Puntos de impacto", desc: "Total generados", number: points},
      {stat: 'shary_by_offers', label: "Puntos Shary", desc: "En préstamos", number: borrow_coins},
      {stat: 'impact_by_offers', label: "Puntos de impacto", desc: "En préstamos", number: borrow_points},
      {stat: 'shary_by_actions', label: "Puntos Shary", desc: "En acciones sostenibles", number: actions_coins},
      {stat: 'impact_by_actions', label: "Puntos de impacto", desc: "En acciones sostenibles", number: actions_points},
      // {stat: 'shary_by_initiatives', label: "Puntos Shary", desc: "En servicio comunitario", number: initiatives_coins},
      // {stat: 'impact_by_initiatives', label: "Puntos de impacto", desc: "En servicio comunitario", number: initiatives_points},
    ]

    //Ranking de los empleados por puntos de impacto acumulados en ese mes vs el acumulado // ranking
    //Ranking por área acumulados de ese mes vs acumulado total // 

    let statistics_ = []
    let charts = {}
    if (roles.includes(config.ROLES.ADMIN) || roles.includes(config.ROLES.COMPANIES)) {
      statistics_ = [...this.state.statistics, active_users, ...pointsAndCoinsObj, actions, prizes, products_borrowed, favors_borrowed]
      charts.coins_pieChart = {series: [borrow_coins, actions_coins], labels: ["Préstamos" , "Acciones sostenibles"]}
      charts.points_pieChart = {series: [borrow_points, actions_points], labels: ["Préstamos" , "Acciones sostenibles"]}
      
      charts.borrows_pieChart = {series: [products_borrowed_number, favors_borrowed_number], labels: ["Productos" , "Favores"]}
      // charts.initiatives_pieChart = {series: [volunteering_number, recycling_number, donations_number], labels: ["Voluntariados" , "Reciclajes", "Donaciones"]}

      charts.actions_lineChart = {series: this.getMonthlySeries(transactions.filter(t => t.type === "action"), "created_at")}
      charts.borrows_lineChart = {series: this.getMonthlySeries(transactions.filter(t => t.type === "borrow"), "created_at")}
      // charts.initiatives_lineChart = {series: this.getMonthlySeries(transactions.filter(t => t.type === "initiative"), "created_at")}
    }
    if (roles.includes(config.ROLES.INITIATIVES)) {
      statistics_ = [...this.state.statistics, pointsAndCoinsObj[6], pointsAndCoinsObj[7]]
    }
    // if (roles.includes(config.ROLES.BENEFITS)) {
    //   statistics_ = [...this.state.statistics, benefits,]
    // }

    this.setState({statistics: statistics_, charts})
  }

  getUsersStatistics = () => {
    let { users } = this.state
    //Cantidad de empleados // getUsers
    let users_count = {stat: 'users_totals', label: "Colaboradores registrados", number: users?.filter(u=>u?.status==="active")?.length}
    let users_active_count = {stat: 'users_totals_active', label: "Colaboradores habilitados", number: users?.filter(u => u.enabled).length}

    this.setState({statistics: [users_active_count, users_count, ...this.state.statistics]})

  }

  getODSStatistics() { 
    const { transactions,timeFilter } = this.state;
    if (transactions.length > 0) {
      const actionsData = transactions
        .filter((tr) => tr.type == "action" && tr.json_data?.points !==0 && tr.json_data?.coins !==0 && tr.archived !== 1)
        .filter(t => timeFilter(new Date(t.created_at)))
        .map((tr) => tr.json_data.action);

      var contributedODS = {};
      for (let act of actionsData) {
        const ods = act.ods?.split(",").map((ods) => ods.trim()).filter(ods => !!ods);
        if (ods)
          for (let odsCode of ods)
            contributedODS[odsCode] = (contributedODS[odsCode] || 0) + 1;
      }

      this.setState({
        contributedODS: Object.keys(contributedODS).map((code) => ({
          code,
          actionsAmount: contributedODS[code],
          squareIcon: config.ODS.find((configODS) => configODS.code == code)
            ?.squareIcon,
        })),
      });  
    }
  }

  getTransactions = async (params) => {
    //let params = {}//owner: this.props.auth.user.unique_id}
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      this.setState({ transactions: transactions.items, });
    }
  }

  getUsers = async (params) => {
    //let params = {}//unique_id: this.props.auth.user.unique_id}
    await this.props.onGetAllUsers(params);
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items, });
    }
  }

  onTimeFilterChange =async (e) => {
    let { users } = this.state
    const timeFilter = this.state.timeFilterDict[e.target.value]
    let users_count = {stat: 'users_totals', label: "Colaboradores registrados", number: users?.filter(u=>u?.status==="active")?.length}
    let fn = () => {
      this.getTransactionsStatistics();
      this.getODSStatistics()
    }
    
    if (this.props.auth.user.roles.includes(config.ROLES.ADMIN) || this.props.auth.user.roles.includes(config.ROLES.COMPANIES)) {
      const colaboradores = this.state.statistics.find((x => x.label === 'Colaboradores habilitados'))
      this.setState({timeFilter, statistics: [colaboradores,users_count]}, fn)
    } else {
      this.setState({timeFilter, statistics: []}, fn)
    }

  }

    numberFormat = new Intl.NumberFormat('de-DE',{})
  render() {
    const { statistics, timeFilterOptions, contributedODS, loading } = this.state
    return (
      <LayoutWithSidebar>
        <Navbar>
          <div className="lg:w-0 lg:flex-1 inline-flext justified-star px-4">
            <span className="light-primary-text text-bold text-3xl">Panel</span>
            <SelectInput selectClassName="select select-sm w-40" options={timeFilterOptions} noError={true} input={{onChange: (e) => this.onTimeFilterChange(e)}} />
          </div>
        </Navbar>
        {loading && <Loader />}
        {this.props.auth.user.roles.includes(config.ROLES.INITIATIVES) && statistics && (
          <>
            <div className="grid grid-flow-row md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 md:gap-6 gap-2 mt-5 py-4 md:px-10">
              <div className="stats card white">
                <div className="stat">
                  <div className="stat-title whitespace-normal">{capitalize(statistics.find(s => s.stat==="shary_by_initiatives")?.label)}</div>
                  <div className="stat-value text-primary">{this.numberFormat.format(statistics.find(s => s.stat==="shary_by_initiatives")?.number)}</div>
                  <div className="stat-desc">{capitalize(statistics.find(s => s.stat==="shary_by_initiatives")?.desc)}</div>
                </div>
              </div>
              <div className="stats card white">
                <div className="stat">
                  <div className="stat-title whitespace-normal">{capitalize(statistics.find(s => s.stat==="impact_by_initiatives")?.label)}</div>
                  <div className="stat-value text-primary">{this.numberFormat.format(statistics.find(s => s.stat==="impact_by_initiatives")?.number)}</div>
                  <div className="stat-desc">{capitalize(statistics.find(s => s.stat==="impact_by_initiatives")?.desc)}</div>
                </div>
              </div>      
            </div>

            <div className="grid grid-flow-row md:grid-cols-3 sm:grid-cols-3 xs:grid-cols-1 lg:grid-cols-5 xl:grid-cols-3 md:gap-6 gap-2 mt-5 py-4 md:px-10">
              <div className="stats card white">
                <div className="stat">
                  <div className="stat-title whitespace-normal">{capitalize(statistics.find(s => s.stat==="volunteering_attended")?.label)}</div>
                  <div className="stat-value text-primary">{this.numberFormat.format(statistics.find(s => s.stat==="volunteering_attended")?.number)}</div>
                  <div className="stat-desc">{capitalize(statistics.find(s => s.stat==="volunteering_attended")?.desc)}</div>
                </div>
              </div>              
              <div className="stats card white">
                <div className="stat">
                  <div className="stat-title whitespace-normal">{capitalize(statistics.find(s => s.stat==="recycling_attended")?.label)}</div>
                  <div className="stat-value text-primary">{this.numberFormat.format(statistics.find(s => s.stat==="recycling_attended")?.number)}</div>
                  <div className="stat-desc">{capitalize(statistics.find(s => s.stat==="recycling_attended")?.desc)}</div>
                </div>
              </div>              
              <div className="stats card white">
                <div className="stat">
                  <div className="stat-title whitespace-normal">{capitalize(statistics.find(s => s.stat==="donations_attended")?.label)}</div>
                  <div className="stat-value text-primary">{this.numberFormat.format(this.numberFormat.format(statistics.find(s => s.stat==="donations_attended")?.number))}</div>
                  <div className="stat-desc">{capitalize(statistics.find(s => s.stat==="donations_attended")?.desc)}</div>
                </div>
              </div>  
            </div>
          </>
        )}

        {!loading && [config.ROLES.COMPANIES, config.ROLES.BENEFITS, config.ROLES.ADMIN].includes(this.props.auth.user.roles[0]) && statistics &&(
          <>
            <div className="grid grid-flow-row md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 md:gap-6 gap-2 mt-5 py-4 md:px-10">
              {statistics.map(({ label, number, desc }, index) => (          
                <div key={label+index} className="stats card white">
                  <div className="stat h-full">
                    <div className="stat-title whitespace-normal">{capitalize(label)}</div>
                    <div className="stat-value text-primary">{this.numberFormat.format(number)}</div>
                    <div className="stat-desc">{desc}</div>
                  </div>
                </div>
              ))}              
            </div>
            {contributedODS && <div className='card white shadow-[0_3px_5px_3px_rgba(0,0,0,0.2)] p-4 bg-success bg-opacity-50 md:mx-10'>
              <h4 className='text-base mb-2 mx-auto'>Hasta ahora, se contribuyó a las siguientes ODS:</h4>
              <div className='flex overflow-x-auto items-center lg:justify-around mb-2 pb-3'>
                {contributedODS.map(ods => (
                  <div className='mx-2 shrink-0' key={ods.code}>
                    <img key={ods.code} src={ods.squareIcon} alt='ods' className='p-1 mx-2 w-24 h-24' />
                    <p className="text-center text-gray-700">{ods.actionsAmount} {ods.actionsAmount > 1 ? "acciones" : "acción"}</p>
                  </div>
                ))}
              </div>
            </div>}
             
           {this.props.auth.user.roles[0] === config.ROLES.COMPANIES &&  <StaticsFlow timeFilter={this.state.timeFilter}/>}
            {this.props.auth.user.roles[0] === config.ROLES.COMPANIES && <>
              <div className="flex flex-col sm:grid sm:grid-flow-row md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:gap-6 gap-2 mt-5 py-4 md:px-10">
                <div className="card white p-4">
                  {this.state.charts?.coins_pieChart && <PieChart title={"Distribución puntos shary"} series={this.state.charts?.coins_pieChart.series} labels={this.state.charts?.coins_pieChart.labels}/>}
                </div>
                <div className="card white p-4">
                  {this.state.charts?.points_pieChart && <PieChart title={"Distribución puntos de impacto"} series={this.state.charts?.points_pieChart.series} labels={this.state.charts?.points_pieChart.labels}/>}
                </div>
                <div className="card white p-4">
                  {this.state.charts?.borrows_pieChart && <PieChart title={"Distribución puntos de préstamos"} series={this.state.charts?.borrows_pieChart.series} labels={this.state.charts?.borrows_pieChart.labels}/>}
                </div>
                {/* <div className="card white p-4">
                  {this.state.charts?.initiatives_pieChart && <PieChart title={"Distribución puntos de iniciativas"} series={this.state.charts?.initiatives_pieChart.series} labels={this.state.charts?.initiatives_pieChart.labels}/>}
                </div> */}
                
                  {false && <LineChart title={"Evolución por mes"} series={this.state.charts?.actions_lineChart.series} labels={months()}/>}
                  <div className="card white p-4">
                    {this.state.charts?.actions_lineChart && <ApexBarChart title={"Evolución por mes acciones"} series={[{data: this.state.charts?.actions_lineChart.series}]} categories={months()}/>}
                  </div>
                  <div className="card white p-4">
                    {this.state.charts?.borrows_lineChart && <ApexBarChart title={"Evolución por mes préstamos"} series={[{data: this.state.charts?.borrows_lineChart.series}]} categories={months()}/>}
                  </div>
                  {/* <div className="card white p-4">
                    {this.state.charts?.initiatives_lineChart && <ApexBarChart title={"Evolución por mes iniciativas"} series={[{data: this.state.charts?.initiatives_lineChart.series}]} categories={months()}/>}
                  </div> */}

              </div>
            </>}
          </>
        )}

      </LayoutWithSidebar>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transactions: state.transactions.list,
    users: state.users.list,
    companies: state.companies.list,
    businesses: state.businesses.list,
    actions: state.actions.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params)),
    onGetAllBusinesses: params => dispatch(businessesActions.getAll(params)),
    onGetAllCompanies: params => dispatch(companiesActions.getAll(params)),
    onGetAllActions: params => dispatch(actionsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Dashboard));