import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import actionActions from '../../context/actions/actions';
import actionsCategory from '../../context/categories/actions';
import actionsTransactions from '../../context/transactions/actions';
import actionsCompany from '../../context/companies/actions';
import Loader from '../../components/commons/Loader';
import PieChart from '../../components/charts/PieChart';
import ApexBarChart from '../../components/charts/ApexBarChart';
import { tr } from 'date-fns/locale';
import { notify } from '../../libraries/notifications';

class StaticsFlow extends Component {
  constructor(props) {
    super(props)
    this.t = this.props.t;
    this.state = {
      loading: false,
      company: null,
      actions: [],
      category: [],
      actionsWithTransactions: [],
    }
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.timeFilter !== this.props.timeFilter) {
      this.getAllTransactionsByActions();
    }
  }


  getData = async () => {
    this.setState({ loading: true });
    await this.getCompany();
    // await this.getAllCategories();
    await this.getAllActionFlow();
    await this.getAllTransactionsByActions();
    this.setState({ loading: false });
  }
  getCompany = async () => {
    const accoundId = this.props.auth.user.accounts[0].id;
    console.log(accoundId);
    const params = {
      owner: accoundId
    }
    console.log(params)
    await this.props.onGetCompanies(params);
    const error = this.props.companies.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ company: this.props.companies.items[0] });
    }
  }

  getAllCategories = async () => {
    const params = {
      "name": "Flow",
    }
    await this.props.onGetCategories(params);
    const error = this.props.actions.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const categories = this.props.categories.items;
      if (!categories.length > 0) this.setState({ categories: [] });

      this.setState({ category: categories[0] });
    }
  }
  getAllActionFlow = async () => {
    const params = {}
    // this.state.category &&( params.type = this.state.category.id);
    console.log(params);
    await this.props.onGetActions();
    const error = this.props.actions.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const actions = this.props.actions.items.filter(tr => tr.json_data?.sub_type == 'questions');
      this.setState({ actions: actions });
    }
  }
  getAllTransactionsByActions = async () => {
    const actions = this.state.actions;
    const transactions = [];
    const Alltransactions = {
      total: 0,
      correctas: 0,
      incorrectas: 0
    };
    for (const action of actions) {
      const params = {
        "target": action.id,
        owner: this.state.company.id
      }
      await this.props.onGetTransactions(params);
      const error = this.props.transactions.error;
      if (error) {
        console.log(error);
        notify(this.t(error.message));
      } else {
        const trx = this.props.transactions.items.filter(t => this.props.timeFilter(new Date(t.created_at)));
        const correctas = trx.filter(t => t.json_data.points > 0 && t.json_data.coins > 0).length;
        const incorrectas = trx.filter(t => t.json_data.points == 0 && t.json_data.coins == 0).length;
        transactions.push({
          action: action,
          transactions: trx,
          series: [trx.length, correctas, incorrectas]
        });
        //hacer valores generales correctas, incorrectas y totales 
        Alltransactions.total += trx.length;
        Alltransactions.correctas += correctas;
        Alltransactions.incorrectas += incorrectas;


        this.setState({ actionsWithTransactions: transactions, alltransactions: Alltransactions });
      }
    }
  }
  render() {
    if (this.state.loading) {
      return <Loader />
    }
    return (
      <div className='card white shadow-[0_3px_5px_3px_rgba(0,0,0,0.2)] p-4 bg-success bg-opacity-50 md:mx-10 my-10'>
        <div>
          <h2 className='mb-4'>Acciones con trivia</h2>
        {this.state.alltransactions &&  <ul className='flex mb-10'>
            <li className='px-3 py-2 border border-gray-500 rounded-lg  flex flex-col justify-center mr-5 w-[150px]'>
              <span className='text-center text-primary'>{`Visualizaciones`}</span>
              <span className='text-center font-bold text-xl text-primary'>{`${this.state.alltransactions?.total}`}</span>
            </li>
            <li className='px-3 py-2 border border-gray-500 rounded-lg  flex flex-col justify-center mr-5 w-[150px]'>
              <span className='text-center text-primary'>{`Correctas`}</span>
              <span className='text-center font-bold text-xl text-primary'>{`${this.state.alltransactions?.correctas}`}</span>
            </li>
            <li className='px-3 py-2 border border-gray-500 rounded-lg  flex flex-col justify-center mr-5 w-[150px]'>
              <span className='text-center text-primary'>{`Incorrectas`}</span>
              <span className='text-center font-bold text-xl text-primary'>{`${this.state.alltransactions?.incorrectas}`}</span>
            </li>
          </ul>}
          <div className="flex flex-row gap-4 overflow-x-scroll">
            {this.state?.actionsWithTransactions?.map((op, index) => (
              <div className="border-l-2 min-w-[300px] max-w-[450px] px-3" key={op.action.name + index}>
                {/* <PieChart  title={op.action.name} series={op.series} labels={["Correctas", "Incorrectas"]} /> */}
                {<ApexBarChart porcenjate={true} title={op.action.name} series={[{ data: op.series }]} categories={["Visualizaciones", "Correctas", "Incorrectas"]} />}
              </div>
            ))}

          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    actions: state.actions.list,
    categories: state.categories.list,
    transactions: state.transactions.list,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetActions: (params) => dispatch(actionActions.getAll(params)),
    onGetCategories: (params) => dispatch(actionsCategory.getAll(params)),
    onGetTransactions: (params) => dispatch(actionsTransactions.getAll(params)),
    onGetCompanies: (params) => dispatch(actionsCompany.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StaticsFlow));
