import React from "react";
import Chart from "react-apexcharts";

export default function PieChart(props) {
	let series = props.series//[44, 55, 13, 43, 22]
	let options = {
      chart: {
        width: 400,
        type: 'pie',
      },
      labels: props.labels,
      colors: ['#297f87', '#ffc93f', '#0fb9b1', '#759699'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

	return (
		<div id="chart" className={""}>
      <h2 className="mb-3 text-base">{props.title}</h2>
			<Chart className="graphic" options={options} series={series} type="donut" width={400} />
		</div>
    )
}
